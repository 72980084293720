// Angular
import { inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
// Constants
import { APP_CONSTANT, ENV_CUSTOMER_LANG, LOCALES } from '@constants';
// RxJS
import { delay, map } from 'rxjs/operators';
// Transloco
import { TranslocoService } from '@jsverse/transloco';
// Services
import { CommonService } from '@core/utils';
import { AppInitLoaderService } from './app-init-loader.service';
import { LayoutService } from './views/pages/layout/services';

export const LangRouteGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  // Inject services
  const router = inject(Router);
  const translocoService = inject(TranslocoService);
  const commonService = inject(CommonService);
  const layoutService = inject(LayoutService);
  const appInitLoaderService = inject(AppInitLoaderService);
  const platformId = inject<Object>(PLATFORM_ID);

  try {
    return appInitLoaderService.appInitDoneSubject.pipe(
      map(() => {
        const availableLangs: Array<string> = translocoService.getAvailableLangs() as Array<string>;
        const lang = route.paramMap.get('lang');

        if (lang && availableLangs.includes(lang)) {
          translocoService.setActiveLang(lang);

          const savedLanguage = commonService.getFromLocalStorage(ENV_CUSTOMER_LANG);

          if (lang !== savedLanguage) {
            const locale = LOCALES.find(item => item.locale === lang);
            layoutService.setCustomerLanguage(locale);
          }
        } else {
          handleInvalidLanguage(router, commonService, platformId);
        }

        return true;
      }),
      delay(200)
    );
  } catch (error) {
    console.log('lang-canactivate-route-error', error);
    return true;
  }
};

/**
 * Handle Invalid Language Route
 * @private
 */
const handleInvalidLanguage = (router: Router, commonService: CommonService, platformId: Object): void => {
  try {
    if (isPlatformBrowser(platformId)) {
      const language = commonService.getFromLocalStorage(ENV_CUSTOMER_LANG);

      if (language && language !== APP_CONSTANT.DFAULT_LOCALE) {
        router.navigateByUrl(`/${language}/refresh`, { skipLocationChange: true }).then(() => {
          window.location.href = `/${language}`;
        });
      } else {
        router.navigateByUrl(`/${APP_CONSTANT.DFAULT_LOCALE}`);
      }
    } else {
      router.navigateByUrl(`/${APP_CONSTANT.DFAULT_LOCALE}`);
    }
  } catch (error) {
    console.log('lang-route-error', error);
  }
};
