// Store
import { Action, ActionReducer, StoreConfig } from "@ngrx/store";
import { InitialState } from "@ngrx/store/src/models";
// Utils
import { CommonService } from "@core/utils";
// Lodash
import { merge } from "lodash";

/**
 * Meta-reducer factory function
 * @export
 * @param {string} localStorageKey
 * @param {CommonService} commonService
 * @returns {StoreConfig<any>}
 */
export function getMetaReducerFactory(localStorageKey: string, commonService: CommonService): StoreConfig<any> {
    return {
        initialState: (): InitialState<any> | undefined => {
            return commonService.getFromLocalStorage(localStorageKey);
        },
        metaReducers: [
            storageMetaReducer(localStorageKey, commonService)
        ],
    };
}

/**
 * Storage Meta Reducer Middlelayer function between Action and Reducer
 * @export
 * @template S
 * @template A
 * @param {string} key
 * @param {CommonService} commonService
 * @returns
 */
export function storageMetaReducer<S, A extends Action = Action>(key: string, commonService: CommonService) {
    return (reducer: ActionReducer<any>): ActionReducer<any> => {
        return (state, action) => {
            const nextState = reducer(state, action);
            const getLsItem: any = commonService.getFromLocalStorage(key);
            const savedState = getLsItem;
            merge(savedState, nextState);
            commonService.setIntoLocalStorage(key, nextState)
            return nextState;
        };
    };
}