// Angular
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, inject } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from "@angular/router";
import { DOCUMENT } from "@angular/common";
// RxJS
import { Subject, filter } from "rxjs";
import { takeUntil } from 'rxjs/operators';
// Services
import { SeoService } from "./core/_base/utils/seo.service";
import { ThemeModeService } from './views/pages/layout/services';
// Environments
import { environment } from "@environment";
// Components
import { CookiePopupComponent, GoogleAnalyticsComponent, ZendeskWidgetComponent } from "./core/_base/layout";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, CookiePopupComponent, GoogleAnalyticsComponent, ZendeskWidgetComponent]
})
export class AppComponent implements OnInit, OnDestroy {

  // Private
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private seoService = inject(SeoService);
  private themeModeService = inject(ThemeModeService);
  private document = inject<Document>(DOCUMENT);
  private destroy$ = new Subject<void>();

  /**
   * Creates an instance of AppComponent.
   * @memberof AppComponent
   */
  constructor() {
    this.setFavicon();
  }

  /**
   * On Init
   * @memberof AppComponent
   */
  ngOnInit() {
    this.themeModeService.init();
    this.setPageTitleOnNavigation();
  }

  /**
   * On Destroy
   * @memberof AppComponent
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Set Favicon
   * @private
   * @memberof AppComponent
   */
  private setFavicon(): void {
    const favicon: HTMLLinkElement = this.document.querySelector('#appFavicon') as HTMLLinkElement;
    favicon.setAttribute('href', environment.favicon);
  }

  /**
   * Set Page Title OnNavigation
   * @private
   * @memberof AppComponent
   */
  private setPageTitleOnNavigation(): void {
    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe(() => this.updatePageTitle());
  }

  /**
   * Update Page Title
   * @private
   * @memberof AppComponent
   */
  private updatePageTitle(): void {
    const route = this.getLastChildRoute(this.activatedRoute.snapshot);
    const skipDefaultTitle = route?.data?.skipDefaultTitle;

    if (!skipDefaultTitle) {
      this.seoService.setPage(route?.data?.title);
    }
  }

  /**
   * Get Last Child Route
   * @private
   * @param {*} route
   * @returns {*}
   * @memberof AppComponent
   */
  private getLastChildRoute(route: any): any {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route;
  }

}
