// Angular
import { Routes } from '@angular/router';
// Guards
import { LangRouteGuard } from './lang-route.guard';
// Components
import { ShellComponent } from './views/shell.component';
// Constants
import { APP_CONSTANT } from '@constants';

// Routes
export const appRoutes: Routes = [
    {
        path: ':lang',
        canActivate: [LangRouteGuard],
        component: ShellComponent,
        loadChildren: () => import('./views/shell.routes').then(m => m.shellRoutes)
    },
    {
        path: "",
        canActivate: [LangRouteGuard],
        children: [],
        pathMatch: "full",
    },
    {
        path: '**',
        redirectTo: `${APP_CONSTANT.DFAULT_LOCALE}/error/404`
    },
];
