// Angular
import { inject, Injectable } from "@angular/core";
import { Translation, TranslocoLoader } from "@jsverse/transloco";
import { HttpClient } from "@angular/common/http";
import { environment } from "@environment";
import { of } from "rxjs";

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
    private http = inject(HttpClient);

    getTranslation(lang: string) {
        try {
            return this.http.get<Translation>(`${environment.baseUrl}/assets/i18n/${lang}.json`);
        } catch (error) {
            console.log('Error in getTranslation(): ', error);
            return of([]);
        }
    }
}