// Angular
import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";

@Component({
    selector: "app-shell",
    templateUrl: "./shell.component.html",
    imports: [RouterOutlet]
})
export class ShellComponent {
}
