// Angular Core Imports
import { ApplicationConfig, APP_ID, provideAppInitializer, inject, ErrorHandler, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { DOCUMENT, IMAGE_CONFIG } from '@angular/common';
import { provideClientHydration, BrowserModule, withEventReplay, withIncrementalHydration } from '@angular/platform-browser';
import { provideHttpClient, withJsonpSupport, withFetch, withInterceptors } from '@angular/common/http';
import { provideRouter, UrlSerializer, withComponentInputBinding, withInMemoryScrolling, withViewTransitions } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';

// Angular Material Imports
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig } from '@angular/material/snack-bar';

// Third-Party Imports
import { LIGHTBOX_CONFIG, LightboxConfig } from 'ng-gallery/lightbox';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { provideLoadingBarRouter } from '@ngx-loading-bar/router';
import { provideTransloco, TranslocoService, translocoConfig } from '@jsverse/transloco';
import { provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore } from '@ngrx/router-store';

// Application Specific Imports
import { APP_INIT_PROVIDER } from './app-init-loader.service';
import { authReducer, AuthEffects, reducers, metaReducers } from './core/auth/auth.store';
import { preloadI18nLocale, LocaleProvider, RecaptchaLocaleProvider, RecaptchaKeyProvider, GEO_IP_INIT_PROVIDER, GlobalErrorHandler, TokenInterceptor, CommonService, StandardUrlSerializer } from 'src/app/core/_base/utils';
import { environment } from '@environment';
import { REG_NR_LS_KEY, FEATURE_REGNR_TOKEN, regnrReducer } from '@app/regnr-store';
import { STORE, LOCALHOST_URL, APP_CONSTANT } from '@constants';
import { getMetaReducerFactory } from '@app-meta-reducer-store';
import { appRoutes } from './app.routes';
import { TranslocoHttpLoader } from './transloco-loader';

// Application Config
export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(
            InlineSVGModule.forRoot({ baseUrl: environment.production ? `${environment.baseUrl}/` : `${LOCALHOST_URL}/` }),
        ),
        provideStore(reducers, { metaReducers }),
        provideRouterStore({ stateKey: 'router' }), // Router state integration
        provideState('auth', authReducer),
        provideState('regnr', regnrReducer, FEATURE_REGNR_TOKEN),
        provideEffects(AuthEffects),
        provideClientHydration(),
        provideRouter(appRoutes,
            withComponentInputBinding(),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' }),
            withViewTransitions(),
        ),
        provideHttpClient(withJsonpSupport(), withFetch(), withInterceptors([TokenInterceptor])),
        APP_INIT_PROVIDER,
        { provide: APP_ID, useValue: 'serverApp' },
        {
            provide: IMAGE_CONFIG,
            useValue: {
                disableImageSizeWarning: true,
                disableImageLazyLoadWarning: true
            }
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline'
            }
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                duration: 5000,
                horizontalPosition: 'center', // Set the horizontal position (start, center, end)
                verticalPosition: 'bottom', // Set the vertical position (top, bottom)
            } as MatSnackBarConfig,
        },
        LocaleProvider,
        RecaptchaLocaleProvider,
        RecaptchaKeyProvider,
        GEO_IP_INIT_PROVIDER,
        environment.isSentryLoggingEnable ? { provide: ErrorHandler, useClass: GlobalErrorHandler } : [],
        {
            provide: LIGHTBOX_CONFIG,
            useValue: {
                keyboardShortcuts: false,
                exitAnimationTime: 1000,
                panelClass: 'fullscreen'
            } as LightboxConfig
        },
        { provide: REG_NR_LS_KEY, useValue: STORE.REGNR_LS_KEY },
        {
            provide: FEATURE_REGNR_TOKEN,
            useFactory: getMetaReducerFactory,
            deps: [REG_NR_LS_KEY, CommonService]
        },
        {
            provide: UrlSerializer,
            useClass: StandardUrlSerializer
        },
        provideTransloco({
            config: translocoConfig({
                availableLangs: APP_CONSTANT.AVIALABLE_LANGS,
                defaultLang: APP_CONSTANT.DFAULT_LOCALE,
                fallbackLang: APP_CONSTANT.DFAULT_LOCALE,
                reRenderOnLangChange: true,
                prodMode: environment.production,
                missingHandler: {
                    logMissingKey: environment.production,
                    allowEmpty: true
                }
            }),
            loader: TranslocoHttpLoader,
        }),
        provideLoadingBarRouter(),
        provideAnimations(),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideAppInitializer(() => {
            const initializerFn = (preloadI18nLocale)(inject(TranslocoService), inject(DOCUMENT));
            return initializerFn();
        })
    ]
}